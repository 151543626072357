<template>
    <div class="layout" flex="dir:top">
        <sys-header></sys-header>
        <div class="autoflex" flex>
            <side-bar></side-bar>
            <div class="autoflex page-container">
                article
            </div>
        </div>
    </div>
</template>

<script>
    import SysHeader from "@/components/SysHeader";
    import SideBar from "@/components/SideBar";

    export default {
        name: "article",
        components: {
            SysHeader,
            SideBar
        }
    }
</script>

<style scoped lang="less">

</style>